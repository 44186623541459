import React from 'react'

function Navbar({ baseUrl, user, loading }) {

    return (
        <div className='h-[5vh] flex justify-between px-20 items-center w-full border-b border-black font-display overflow-hidden'>


            <div className='flex justify-between w-fit items-center space-x-10'>
                <a className='text-lg flex justify-center items-center gap-2 transition duration-500' href='/'>
                    <span className='text-xl text-[#35b38c]'>{`Bitcolab`}</span>
                </a>
                <ul className='flex gap-5 items-center text-xs'>
                    <a href={'/'}
                        className='transition duration-500 hover:text-[#35b38c] font-semibold'>{`CEX Arbitrage`}
                    </a>
                    <a href={'/'}
                        className='opacity-10 transition duration-500  font-semibold'>{`DEX Arbitrage`}
                    </a>
                    {/* {
                        user && (
                            <a href={'/alerts'}
                                className='transition duration-500  font-semibold'>{`Alerts`}
                            </a>
                        )
                    } */}
                </ul>
            </div>
        </div >
    )
}

export default Navbar