import React from 'react';
import { Email } from '@mui/icons-material';
function Footer() {
    return (
        <div className="h-[10vh] w-full text-xs flex flex-col justify-center items-center">
            <div className='flex space-x-1 items-center'>
                <span className='scale-75'><Email /></span>
                <span>thebitcolab@gmail.com</span>
            </div>
        </div>
    );
}

export default Footer;