import React, { useState } from 'react'

function Calculate({ data }) {
    const [quantity, setQuantity] = useState(1000)

    return (
        <div className='w-[45vh] h-[40vh] flex flex-col gap-y-2'>
            <div className='flex items-center justify-center py-2 gap-x-2 text-lg'>
                <img className='rounded-full' width={40} height={40} src={data.icon} />
                <span>{data.name}</span>
            </div>
            <div className='flex flex-col px-4 text-base'>
                <div className='flex'>
                    <span>{data.exchange1} : $</span>
                    <span>{data.exchange1_price}</span>
                </div>
                <div className='flex'>
                    <span>{data.exchange2} : $</span>
                    <span>{data.exchange2_price}</span>
                </div>
                <div className='flex'>
                    <span>Price Gap : %</span>
                    <span>{data.gap.toFixed(1)}</span>
                </div>
                <div className='flex'>
                    <span>Withdrawl Fees : $</span>
                    <span>{data.withdrawFee.toFixed(2)}</span>
                </div>
            </div>
            <div>
                <form className='flex px-4'>
                    <input className='px-3 py-1 outline-none' min={1} value={quantity} onChange={(e) => setQuantity(e.target.value)} type="number" placeholder='Enter Amount' />
                </form>
            </div>
            <div className='flex flex-col text-base'>
                <span>for {quantity} {data.symbol.toUpperCase()}</span>
                <span>Total Cost : ${quantity * data.exchange2_price.toFixed(5)}</span>
                <span className={`${((quantity * (data.exchange1_price - data.exchange2_price)) - data.withdrawFee).toFixed(2) <= 0 ? 'text-red-600' : 'text-green-600'}`}>
                    Profit : ${((quantity * (data.exchange1_price - data.exchange2_price)) - data.withdrawFee).toFixed(2)}
                </span>
            </div>

        </div>
    )
}

export default Calculate