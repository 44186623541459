import './App.css';
import { Route, Routes } from 'react-router-dom';
import 'react-loading-skeleton/dist/skeleton.css'
import Navbar from './components/Navbar';
import PrimaryNav from './components/PrimaryNav';
import MobileNav from './components/MobileNav';
import Footer from "./components/Footer";
import Arbitrage from './pages/Arbitrage';
import auth from './lib/Firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import Alerts from './pages/Alerts';
import { NotificationProvider } from './components/NotificationContext';
import ShowAlert from './components/ShowAlert';

function App() {
    const baseUrl = process.env.REACT_APP_BASEURL
    const [user, loading] = useAuthState(auth)

    return (

        <div className='App font-display'>
            <div className='hidden lg:block'>
                <PrimaryNav user={user} loading={loading} />
                <Navbar baseUrl={baseUrl} user={user} loading={loading} />
            </div>

            <div className='lg:hidden'>
                <MobileNav />
            </div>

            <Routes>
                <Route path='/' element={<Arbitrage baseUrl={baseUrl} user={user} loading={loading} />}></Route>
                {/* <Route path='/alerts' element={<Alerts baseUrl={baseUrl} user={user} loading={loading} />}></Route> */}
            </Routes>

            <div className={'border-t border-[#333333]'}>
                <Footer />
            </div>
            {/* <div>
                <ShowAlert />
            </div> */}

        </div>
    );
}

export default App;
