import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import ArbitrageData from '../components/ArbitrageData'

function Arbitrage({ baseUrl, user, loading }) {
    const [arbitragedata, setArbitrageData] = useState()

    const getToken = useCallback(async () => {
        try {
            if (user) {
                const tokenResult = await user.getIdTokenResult(true);
                return tokenResult.token;
            } else {
                return null;
            }
        } catch (error) {
            console.error('Error fetching token:', error);
            return null;
        }
    }, [user]);

    const fetchArbitrage = async () => {
        const token = await getToken()
        const es = new EventSource(`${baseUrl}/api/arbitrage?token=${token}`);
        es.onmessage = (event) => setArbitrageData(JSON.parse(event.data));
        es.onerror = (err) => console.error('Error:', err);
        es.onopen = () => console.log('Connection opened');
    }
    useEffect(() => {
        if (!loading) {
            fetchArbitrage()
        }
    }, [loading])

    return (
        <div className='px-4 md:px-20 lg:px-32 min-h-screen'>
            <span className='flex text-xl font-display mt-5'>CEX Arbitrage</span>

            <div className='overflow-x-scroll mt-10 text-gray-400'>
                {
                    arbitragedata && (
                        <div className='flex'>
                            <button className='bg-opacity-80 px-2 flex justify-center items-center space-x-2'>
                                <div className='bg-green-700 w-2 h-2 rounded-full animate-pulse'></div>
                                <span>Live</span>
                            </button>
                        </div>
                    ) || (
                        <div className='flex'>
                            <button className='bg-opacity-80 px-2 flex justify-center items-center space-x-2'>
                                <div className='bg-red-700 w-2 h-2 rounded-full animate-pulse'></div>
                                <span>Live</span>
                            </button>
                        </div>
                    )
                }
                <ArbitrageData arbitragedata={arbitragedata} />
            </div>
            <span className='flex text-sm font-display mt-5'>*Supported exchanges are  Binance, Bitget,Mexc(mxc), Gate.io, Huobi, Poloniex, Xt, Whitebit, Digifinex, Coinex,
                Bitrue, Kucoin, Bigone, Hitbtc, FmFw(bitcoin_com)
            </span>
        </div>
    )
}

export default Arbitrage