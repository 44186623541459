import React, { useState } from 'react'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DoneOutlineOutlinedIcon from '@mui/icons-material/DoneOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CalculateIcon from '@mui/icons-material/Calculate';
import Calculate from './Calculate';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export default function ArbitrageData({ arbitragedata }) {

    const [currentPage, setcurrentPage] = useState(1);
    const [postsPerPage, setpostsPerPage] = useState(100);

    const lastPostIndex = currentPage * postsPerPage;
    const firstPostIndex = lastPostIndex - postsPerPage;
    const handlePage = ((selectedPage) => {
        setcurrentPage(selectedPage);
    })

    const [showCal, setShowCal] = useState(false)
    const [calData, setCalData] = useState()

    return (
        <>{arbitragedata && (

            <div className='font-display text-sm flex flex-col space-y-2'>

                <table>
                    <thead>
                        <tr className='h-[5vh] border-b border-black'>
                            <th className=''><span className='flex'></span></th>
                            <th className=''><span className='flex'>Coin</span></th>
                            <th className=''><span className='flex'>Difference</span></th>
                            <th className=''><span className='flex'>Exchange 1</span></th>
                            <th className=''><span className='flex'>Price</span></th>
                            <th className=''><span className='flex'>Deposit</span></th>
                            <th className=''><span className='flex'>Networks</span></th>

                            <th className=''><span className='flex'>Exchange 2</span></th>
                            <th className=''><span className='flex'>Price</span></th>
                            <th className=''><span className='flex'>Withdraw</span></th>
                            <th className=''><span className='flex'>Networks(fees)</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        {arbitragedata && arbitragedata.slice(firstPostIndex, lastPostIndex).map((item, index) => (
                            <tr key={index} className={`border-b border-black h-[5vh] ${item.price_gap.exchange1_status.active == true &&
                                item.price_gap.exchange2_status.active === true && item.price_gap.exchange1_status.deposit === true && item.price_gap.exchange2_status.withdraw === true ?
                                "bg-[#35b38c] text-black " : ""
                                }`} >
                                <td>{((currentPage - 1) * 100) + index + 1}</td>
                                <td className='px-1'>
                                    <span className='flex justify-start items-center gap-1'>
                                        <img className='rounded-full' width={24} height={24} src={item.icon} />
                                        <p className='truncate font-semibold'>{item.symbol.toUpperCase()}</p>
                                    </span>
                                </td>


                                <td className=''><p className='flex'>{item.gap.toFixed(2)}%</p></td>

                                <td className=''><span className='flex'>{item.price_gap.exchange1}
                                    <a className='flex' href={item.price_gap.exchange1_url ? item.price_gap.exchange1_url : ''} target='_blank'>({item.price_gap.exchange1_pair}) <OpenInNewIcon fontSize='small' /></a>
                                </span></td>
                                <td className=''><span className='flex justify-stretch'>${item.price_gap.exchange1_price}</span></td>


                                <td className=''>
                                    <span className='flex'>{item.price_gap.exchange1_status.deposit ? <DoneOutlineOutlinedIcon /> : <CancelOutlinedIcon />}</span>
                                </td>
                                <td className=''>
                                    <span className='flex gap-x-1'>
                                        {
                                            item.price_gap.exchange1_status.networks != null &&
                                                item.price_gap.exchange1_status.networks.filter(item => item.deposit).length != 0 ?
                                                item.price_gap.exchange1_status.networks.filter(item => item.deposit).flatMap((item, index) => {
                                                    return <p key={index}>{item.chain.toLowerCase()}</p>
                                                }) :
                                                "-"
                                        }
                                    </span>
                                </td>

                                <td className=''><span className='flex'>{item.price_gap.exchange2}
                                    <a className='flex' href={item.price_gap.exchange2_url ? item.price_gap.exchange2_url : ''} target='_blank'>({item.price_gap.exchange2_pair}) <OpenInNewIcon fontSize='small' /></a>

                                </span></td>
                                <td className=''><span className='flex'>${item.price_gap.exchange2_price}</span></td>

                                <td className=''>
                                    <span className='flex'>{item.price_gap.exchange2_status.withdraw ? <DoneOutlineOutlinedIcon /> : <CancelOutlinedIcon />}</span>
                                </td>

                                <td className=''>
                                    <span className='flex gap-x-1'>
                                        {
                                            item.price_gap.exchange2_status.networks != null &&
                                                item.price_gap.exchange2_status.networks.filter(item => item.withdraw).length != 0 ?
                                                item.price_gap.exchange2_status.networks.filter(network => network.withdraw).flatMap((network, index) => {
                                                    return <p key={index}>{network.chain.toLowerCase()}({network.fee ? `$${(network.fee * item.price_gap.exchange2_price).toFixed(3)}` : "-"})</p>

                                                }) :
                                                "-"
                                        }
                                    </span>
                                </td>
                                <td className=''>
                                    <span>
                                        <button onClick={() => {
                                            setShowCal(true)
                                            setCalData({
                                                id: item.id,
                                                name: item.name,
                                                symbol: item.symbol,
                                                icon: item.icon,
                                                gap: item.gap,
                                                exchange1: item.price_gap.exchange1,
                                                exchange2: item.price_gap.exchange2,
                                                exchange1_price: item.price_gap.exchange1_price,
                                                exchange2_price: item.price_gap.exchange2_price,
                                                withdrawFee: item.price_gap.exchange2_status.fee ? item.price_gap.exchange2_status.fee * item.price_gap.exchange2_price : 0,

                                            })
                                        }}>
                                            <CalculateIcon />
                                        </button>
                                    </span>
                                </td>
                            </tr>
                        )
                        )}
                    </tbody>
                    {
                        showCal && (
                            <div className='fixed bottom-2 flex flex-col bg-[#121212] border border-[#333333] overflow-hidden overflow-y-scroll'>
                                <div className='font-semibold flex justify-end px-2 py-1'>
                                    <button onClick={() => setShowCal(false)}><CancelOutlinedIcon /></button>
                                </div>
                                <div>
                                    <Calculate data={calData} />
                                </div>
                            </div>
                        )
                    }
                </table>
                <div>
                    {arbitragedata && (
                        <div className='flex justify-center'>
                            {currentPage > 1 && (
                                <span
                                    className='transition delay-300 hover:-translate-x-2 cursor-pointer flex items-center p-1 rounded-lg'
                                    onClick={() => handlePage(currentPage - 1)}
                                >
                                    <ArrowBackIcon />
                                </span>
                            )}

                            {[...Array(Math.ceil(arbitragedata.length / postsPerPage))].map((_, i) => {
                                const totalPages = Math.ceil(arbitragedata.length / postsPerPage);
                                const isFirstPage = i === 0;
                                const isLastPage = i === totalPages - 1;
                                const isCurrentPage = currentPage === i + 1;
                                const isNearCurrent = Math.abs(currentPage - (i + 1)) <= 2;

                                if (isFirstPage || isLastPage || isCurrentPage || isNearCurrent) {
                                    return (
                                        <span
                                            key={i}
                                            className={`${isCurrentPage ? 'bg-black' : ''} cursor-pointer px-4 py-2 rounded-full transition delay-300 scale-75`}
                                            onClick={() => handlePage(i + 1)}
                                        >
                                            {i + 1}
                                        </span>
                                    );
                                }

                                if (i === currentPage - 3 || i === currentPage + 1) {
                                    return (
                                        <span key={i} className='px-4 py-2'>
                                            ...
                                        </span>
                                    );
                                }

                                return null;
                            })}

                            {currentPage < Math.ceil(arbitragedata.length / postsPerPage) && (
                                <span
                                    className='transition delay-300 hover:translate-x-2 cursor-pointer flex items-center p-1 rounded-lg'
                                    onClick={() => handlePage(currentPage + 1)}
                                >
                                    <ArrowForwardIcon />
                                </span>
                            )}
                        </div>
                    )}

                </div>
            </div >
        ) || (
                <div className='h-screen flex flex-col gap-y-2 opacity-15'>
                    <div className='w-full h-[4vh] bg-black animate-pulse'></div>
                    <div className='flex flex-col space-y-1'>
                        <div className='w-full h-[4vh] bg-black animate-pulse'></div>
                        <div className='w-full h-[4vh] bg-black animate-pulse'></div>
                        <div className='w-full h-[4vh] bg-black animate-pulse'></div>
                        <div className='w-full h-[4vh] bg-black animate-pulse'></div>
                        <div className='w-full h-[4vh] bg-black animate-pulse'></div>
                        <div className='w-full h-[4vh] bg-black animate-pulse'></div>
                        <div className='w-full h-[4vh] bg-black animate-pulse'></div>
                        <div className='w-full h-[4vh] bg-black animate-pulse'></div>
                        <div className='w-full h-[4vh] bg-black animate-pulse'></div>
                        <div className='w-full h-[4vh] bg-black animate-pulse'></div>
                        <div className='w-full h-[4vh] bg-black animate-pulse'></div>
                        <div className='w-full h-[4vh] bg-black animate-pulse'></div>
                        <div className='w-full h-[4vh] bg-black animate-pulse'></div>
                        <div className='w-full h-[4vh] bg-black animate-pulse'></div>
                        <div className='w-full h-[4vh] bg-black animate-pulse'></div>
                        <div className='w-full h-[4vh] bg-black animate-pulse'></div>

                    </div>
                </div>
            )

        }
        </>
    )
}
